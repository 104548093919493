import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { State } from '@wam/shared';
import { JwtService } from './jwt.service';
import { authenticationSelectors } from './state/authentication.selectors';
import * as authenticationActions from './state/authentication.actions';

@Injectable({
  providedIn: 'root',
})
export class IdTokenService {
  private justRefreshed = false;

  constructor(
    private store: Store<State>,
    private jwtHelperService: JwtService,
  ) {}

  getActiveIdToken(refreshOnExpiredToken = true): Observable<string> {
    return combineLatest([
      this.store.pipe(select(authenticationSelectors.getIdToken)),
      this.store.pipe(select(authenticationSelectors.getPending)),
    ]).pipe(
      filter(([_, pending]) => !pending),
      map(([token]) => {
        if (refreshOnExpiredToken && this.jwtHelperService.isTokenExpired(token)) {
          if (this.justRefreshed) {
            this.store.dispatch(authenticationActions.logOut());
          } else {
            this.store.dispatch(authenticationActions.refreshToken());
            this.justRefreshed = true;
            return null;
          }
        } else {
          this.justRefreshed = false;
          return token;
        }
      }),
      filter((token) => token !== null),
    );
  }
}
