import { createAction, props } from '@ngrx/store';
import { RosteringProvider, AuthenticationType, User } from '@wam/shared';

export const logIn = createAction('[Login Component] Log In');
export const logInActionSuccess = createAction('[AuthenticationEffects] Log In Action Success');

export const signUp = createAction('[Signup Component] Sign Up');

export const authorize = createAction('[Logged In Component] Authorize', props<{ code: string }>());

export const authorizeWithTokens = createAction(
  '[Logged In Component] Authorize With Tokens',
  props<{
    idToken: string;
    refreshToken?: string;
    logoutUrl?: string;
    includeLogoutToken?: boolean;
  }>(),
);

export const updateCurrentUser = createAction(
  '[Authentication API] Update Current User',
  props<{ user: User; refreshed?: boolean }>(),
);

export const logInSuccess = createAction('[Authentication Effects] Log In Success');

export const refreshToken = createAction('[API Gateway Service] Refresh Token');
export const refreshTokenFailed = createAction('[API Gateway Service] Refresh Token Failed');

export const logOut = createAction('[Logout Component] Log Out');

export const updateAuthenticationType = createAction(
  '[Authentication API] Update Authentication Type',
  props<{ authenticationType: AuthenticationType }>(),
);

export const getReadOnly = createAction('[Authentication API] Get read only');

export const getReadOnlySuccess = createAction(
  '[Authentication API] Get read only success',
  props<{ readOnly: boolean }>(),
);

export const updateClientId = createAction(
  '[Login API] Update Client ID',
  props<{ clientId: string }>(),
);

export const getOrgCode = createAction('[App Component] Get Org Code');

export const getOrgCodeSuccess = createAction(
  '[App Component] Get Org Code Success',
  props<{ orgCode: string }>(),
);

export const getOrgCodeFailure = createAction('[App Component] Get Org Code Failure');

export const performLogoutSteps = createAction('[Authentication Effects] Perform Logout Steps');

export const determineRosteringProvider = createAction(
  '[Authentication Side Effect] Determine Rostering Provider',
);

export const determineRosteringProviderSuccess = createAction(
  '[Batch API] Determine Rostering Provider Success',
  props<{ rosteringProvider: RosteringProvider }>(),
);

export const determineRosteringProviderFailed = createAction(
  '[Batch API] Determine Rostering Provider Failed',
  props<{ error: string }>(),
);

export const clearNgRxStore = createAction('[Authentication Effects] Clear NgRxStore on logout');
