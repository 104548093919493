import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from '@wam/shared';

const getAuthenticationFeatureState = createFeatureSelector<
  AuthenticationState & { clientId: string; orgCode?: string }
>('authentication');

const getCurrentUser = createSelector(getAuthenticationFeatureState, (state) => state.user);

const getPending = createSelector(getAuthenticationFeatureState, (state) => state.pending);

const getName = createSelector(getCurrentUser, (user) => user?.name);

const getIdToken = createSelector(getCurrentUser, (user) => user?.idToken);

const getRefreshToken = createSelector(getCurrentUser, (user) => user?.refreshToken);

const getUserRoles = createSelector(getCurrentUser, (user) => user?.roles);

const getAuthenticationType = createSelector(
  getAuthenticationFeatureState,
  (state) => state.authenticationType,
);

const isReadOnly = createSelector(getAuthenticationFeatureState, (state) => state.readOnly);

const getClientId = createSelector(getAuthenticationFeatureState, (state) => state.clientId);

const getLogoutUrl = createSelector(getAuthenticationFeatureState, (state) => state.logoutUrl);

const includeLogoutToken = createSelector(
  getAuthenticationFeatureState,
  (state) => state.includeLogoutToken,
);

const getOrgCode = createSelector(getAuthenticationFeatureState, (state) => state.orgCode);

const getRosteringProvider = createSelector(
  getAuthenticationFeatureState,
  (state) => state.rosteringProvider,
);

export const authenticationSelectors = {
  getCurrentUser,
  getPending,
  getName,
  getIdToken,
  getRefreshToken,
  getAuthenticationType,
  isReadOnly,
  getClientId,
  getLogoutUrl,
  includeLogoutToken,
  getUserRoles,
  getOrgCode,
  getRosteringProvider,
};
