import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Deserializer, Serializer } from 'jsonapi-serializer';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { settingsSelectors, State } from '@wam/shared';
import { IdTokenService } from './id-token.service';
import { isArray, uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ApiGatewayService {
  constructor(
    private store: Store<State>,
    private http: HttpClient,
    private idTokenService: IdTokenService,
  ) {}

  private jsonApiBody(body: object, type: string, attributeCase: string): string {
    return new Serializer(type, {
      attributes: isArray(body)
        ? uniq(body.flatMap((item) => Object.keys(item)))
        : Object.keys(body),
      keyForAttribute: attributeCase,
      pluralizeType: false,
    }).serialize(body);
  }

  private decodeResponse<T>(
    jsonApiResult: T,
    relTypes: string[] = [],
    keyForAttribute: string | Function = 'camelCase',
    onlyData = true,
  ): Subject<T> {
    const resultSubject = new Subject<T>();
    const deserializerOptions = {
      keyForAttribute,
    };
    relTypes.forEach((type) => {
      deserializerOptions[type] = {
        valueForRelationship: (relationship: { id: number; attributes: object }) => {
          return {
            id: relationship.id,
            ...relationship.attributes,
          };
        },
      };
    });
    new Deserializer(deserializerOptions).deserialize(jsonApiResult, (error, result) => {
      resultSubject.next(
        onlyData ? result : { data: result, meta: (jsonApiResult as { data; meta }).meta },
      );
      resultSubject.complete();
    });
    return resultSubject;
  }

  withSettings(refreshOnExpiredToken = false): Observable<{
    invokeUrl: string;
    protocol: string;
    headers: { 'X-Api-Key': string; Authorization: string };
  }> {
    return combineLatest([
      this.idTokenService.getActiveIdToken(refreshOnExpiredToken),
      this.store.select(settingsSelectors.getApiSettings),
    ]).pipe(
      map(([token, apiSettings]) => ({
        invokeUrl: apiSettings.url,
        protocol: apiSettings.protocol,
        headers: {
          'X-Api-Key': apiSettings.key,
          Authorization: `Bearer ${token}`,
        },
      })),
      take(1),
    );
  }

  simpleGetWithHeaders<T>(path: string): Observable<HttpResponse<T>> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.get<T>(`${protocol}://${invokeUrl}/${path}`, { headers, observe: 'response' }),
      ),
    );
  }

  simpleGet<T>(path: string): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.get<T>(`${protocol}://${invokeUrl}/${path}`, { headers }),
      ),
    );
  }

  get<T>(
    path: string,
    options: Partial<{
      relTypes: string[];
      keyForAttribute: string | Function;
      refreshOnExpiredToken: boolean;
      onlyData: boolean;
    }> = {},
  ): Observable<T> {
    const {
      relTypes = [],
      keyForAttribute = 'camelCase',
      refreshOnExpiredToken = false,
      onlyData = true,
    } = options;
    return this.getWithMetadata(path, relTypes, keyForAttribute, refreshOnExpiredToken).pipe(
      map((value) => (onlyData ? (value.data as T) : (value as T))),
    );
  }

  getWithMetadata(
    path: string,
    relTypes: string[] = [],
    keyForAttribute: string | Function = 'camelCase',
    refreshOnExpiredToken = false,
  ): Observable<{ data; meta }> {
    return this.withSettings(refreshOnExpiredToken).pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.get<{ data; meta }>(`${protocol}://${invokeUrl}/${path}`, {
          headers,
          observe: 'response',
        }),
      ),
      mergeMap((jsonApiResult) => {
        return this.decodeResponse<{ data; meta }>(
          jsonApiResult.body,
          relTypes,
          keyForAttribute,
          false,
        ).pipe(map((response) => ({ ...response, headers: jsonApiResult.headers })));
      }),
      take(1),
    );
  }

  simplePost<T>(path: string, body: object): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.post<T>(`${protocol}://${invokeUrl}/${path}`, body, { headers }),
      ),
    );
  }

  post<T>(
    path: string,
    body: object,
    type: string = 'data',
    relTypes: string[] = [],
    attributeCase: string = 'camelCase',
  ): Observable<T> {
    const jsonApiBody = this.jsonApiBody(body, type, attributeCase);

    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.post<T>(`${protocol}://${invokeUrl}/${path}`, jsonApiBody, { headers }),
      ),
      mergeMap((jsonApiResult) => {
        if (jsonApiResult && jsonApiResult['data']) {
          return this.decodeResponse<T>(jsonApiResult, relTypes, attributeCase);
        } else {
          return of(jsonApiResult);
        }
      }),
    );
  }

  simplePut<T>(path: string, body: object): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.put<T>(`${protocol}://${invokeUrl}/${path}`, body, { headers }),
      ),
    );
  }

  put<T>(
    path: string,
    body: object,
    type: string = 'data',
    attributeCase: string = 'camelCase',
  ): Observable<T> {
    const jsonApiBody = this.jsonApiBody(body, type, attributeCase);

    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.put<T>(`${protocol}://${invokeUrl}/${path}`, jsonApiBody, { headers }),
      ),
      mergeMap((jsonApiResult) => this.decodeResponse<T>(jsonApiResult, [], attributeCase)),
    );
  }

  patch<T>(
    path: string,
    body: object,
    type: string = 'data',
    attributeCase: string = 'camelCase',
  ): Observable<T> {
    const jsonApiBody = this.jsonApiBody(body, type, attributeCase);

    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.patch<T>(`${protocol}://${invokeUrl}/${path}`, jsonApiBody, { headers }),
      ),
      mergeMap((jsonApiResult) => this.decodeResponse<T>(jsonApiResult, [], attributeCase)),
    );
  }

  simplePatch<T>(path: string, body: object): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.patch<T>(`${protocol}://${invokeUrl}/${path}`, body, { headers }),
      ),
    );
  }

  delete<T>(path: string): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.delete<T>(`${protocol}://${invokeUrl}/${path}`, { headers }),
      ),
    );
  }

  deleteWithBody<T>(path: string, body: object): Observable<T> {
    return this.withSettings().pipe(
      switchMap(({ invokeUrl, protocol, headers }) =>
        this.http.request<T>('delete', `${protocol}://${invokeUrl}/${path}`, { headers, body }),
      ),
    );
  }
}
